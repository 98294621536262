import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import App from './App';
import { SidebarDrawerProvider } from './contexts/SidebarDrawerContext';
import { ModalProvider } from './contexts/ModalContext';

import theme from './styles/theme';

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <SidebarDrawerProvider>
        <ModalProvider>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </ModalProvider>
      </SidebarDrawerProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
