import React from 'react';
import {
  Flex,
  Text,
  VStack,
  Box,
  useBreakpointValue,
  HStack,
  Image,
  Icon,
  Link,
  Button,
  Stack,
} from '@chakra-ui/react';

import { MdCheck } from 'react-icons/md';
import ContainerPublic from '../../components/ContainerPublic';

import sonFatherBanner from '../../assets/son-father-banner.jpg';

const Consultoria: React.FC = () => {
  const isDisplayBiggerThanMedium = useBreakpointValue({
    base: false,
    md: true,
  });

  const isDisplayBiggerThanXLarge = useBreakpointValue({
    base: false,
    xl: true,
  });

  const isSmallDisplay = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <ContainerPublic>
      <Box marginX="auto">
        <Flex maxHeight="800px" minHeight="800px">
          <Image
            width="100%"
            objectFit="cover"
            // height="3456px"
            src={sonFatherBanner}
            alt="Son Father Banner"
            loading="lazy"
          />
          <Flex
            w="100%"
            // h="100%"
            top={isDisplayBiggerThanXLarge ? '120px' : '80px'}
            bottom="auto"
            position="absolute"
            margin="auto"
            // p={isDisplayBiggerThanMedium ? '8' : '4'}
          >
            <VStack
              width="fit-content"
              maxWidth={!isDisplayBiggerThanMedium ? '100%' : '600px'}
              align={!isDisplayBiggerThanMedium ? 'center' : 'flex-start'}
              spacing="0"
              position="relative"
              left={isDisplayBiggerThanXLarge ? '-350px' : 'auto'}
              padding="24px"
              borderRadius={!isDisplayBiggerThanMedium ? 'none' : '8px'}
              // justifyContent="center"
              marginX="auto"
            >
              <Text
                as="h1"
                fontSize={isSmallDisplay ? '1.3rem' : '2.125rem'}
                fontWeight="extrabold"
                textAlign="start"
                width="100%"
              >
                Soluções em Consultoria
              </Text>
              <Text
                fontSize="16px"
                paddingTop="16px"
                textAlign="start"
                width="100%"
              >
                Seu patrimônio merece assessoria <strong>exclusiva.</strong>
              </Text>
            </VStack>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          h="100%"
          p={isDisplayBiggerThanMedium ? '8' : '4'}
          marginX="auto"
          align="center"
          justifyContent="center"
          marginBottom={isDisplayBiggerThanMedium ? '0px' : '32px'}
        >
          <VStack w="100%" h="100%" maxWidth="1140px" spacing="64px">
            <VStack width="100%">
              <Text
                as="h1"
                fontSize={isSmallDisplay ? '1.3rem' : '1.5rem'}
                fontWeight="extrabold"
                textAlign="start"
                width="100%"
              >
                Planejamento Sucessório e Proteção Patrimonial
              </Text>
              <Text
                fontSize="16px"
                paddingTop="16px"
                textAlign="start"
                width="100%"
              >
                A sucessão em Grupos Familiares é um processo complexo
                envolvendo <strong>FAMÍLIA, PROPRIEDADE E EMPRESA</strong>. É o
                desafio de conciliar os conflitos de interesses pessoais e
                profissionais na transição de uma geração para a outra, buscando
                questionar e prevenir as dificuldades e principalmente facilitar
                a sucessão e preservação do patrimônio.
              </Text>
              <Text
                fontSize="16px"
                paddingTop="16px"
                textAlign="start"
                width="100%"
              >
                O planejamento sucessório viabiliza a criação de regras a serem
                seguidas pelos herdeiros antes e após a morte do titular dos
                bens, além de gerar{' '}
                <strong>economia tributária considerável.</strong>
              </Text>
              <Text
                fontSize="16px"
                paddingTop="16px"
                textAlign="start"
                width="100%"
              >
                O planejamento sucessório, através da{' '}
                <strong>holding familiar</strong>, nada mais é do que um sistema
                de administração de bens, que visa proteger e perpetuar o
                patrimônio junto ao seu núcleo familiar, protegendo-o de
                terceiros e até mesmo de impostos e tributos excessivos em um
                eventual inventário.
              </Text>
              <Text
                fontSize="16px"
                paddingTop="16px"
                fontWeight="bold"
                textAlign="start"
                width="100%"
              >
                Principais Benefícios de uma Holding Familiar:
              </Text>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Em caso de ausência ou morte do patriarca fundador da empresa
                  na atividade empresarial não ficará paralisada nem será
                  dificultada por conta do processo moroso no processo de
                  inventário.
                </Text>
              </HStack>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Evita conflitos familiares e garante a vontade de seus
                  criadores prevaleça a divisão patrimonial.
                </Text>
              </HStack>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Permite economia e planejamento em termos tributários.
                </Text>
              </HStack>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Protege legalmente seus bens, em relação a exposição de todo o
                  patrimônio quando da solicitação da própria declaração de IR.
                </Text>
              </HStack>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Impõe autoridade a sua empresa, que passa a ser entendida como
                  um grupo cujo controle administrativo é maior e mais
                  harmônico.
                </Text>
              </HStack>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Riscos trabalhistas, evitando que os bens pessoais respondam
                  por possíveis falhas na empresa.
                </Text>
              </HStack>
              <HStack width="100%" paddingLeft="8px" paddingTop="16px">
                <Icon
                  alignSelf="start"
                  as={MdCheck}
                  color="#C7ED84"
                  height="32px"
                  width="32px"
                />
                <Text fontSize="16px" textAlign="start" width="100%">
                  Riscos familiares - A dissolução de um casamento com regime de
                  comunhão de bens, o falecimento de um sócio e a sua
                  consequente partilha de bens em inventário, tudo isso pode
                  afetar profundamente a saúde financeira e o quadro patrimonial
                  da empresa.
                </Text>
              </HStack>
            </VStack>

            <Stack>
              <Link
                href="http://api.whatsapp.com/send?1=pt_BR&phone=5545999149052"
                width="100%"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  width="100%"
                  height="50px"
                  background="#A9E838"
                  color="#121b21"
                  // borderTopRadius="none"
                  _hover={{ bg: '#98D132' }}
                >
                  QUERO UM ORÇAMENTO
                </Button>
              </Link>
            </Stack>
          </VStack>
        </Flex>
      </Box>
    </ContainerPublic>
  );
};

export default Consultoria;
