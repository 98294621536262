import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

// import SignUp from '../pages/SignUp';
import Home from '../pages/Home';
import QuemSomos from '../pages/QuemSomos';
import Consultoria from '../pages/Consultoria';
import Contabilidade from '../pages/Contabilidade';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/quemsomos" exact component={QuemSomos} />
    <Route path="/consultoria" exact component={Consultoria} />
    <Route path="/contabilidade" exact component={Contabilidade} />
    <Route path="/signin" component={SignIn} />
    {/* <Route path="/signup" component={SignUp} /> */}
    <Route path="/forgot-password" component={ForgotPassword} />
    {/* <Route path="/reset-password" component={ResetPassword} /> */}
  </Switch>
);

export default Routes;
