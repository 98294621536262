import React from 'react';

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';

import SidebarNav from './SidebarNav';
import { useSidebarDrawer } from '../../../contexts/SidebarDrawerContext';

const Sidebar: React.FC = () => {
  const { isOpen, onClose } = useSidebarDrawer();

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay height="100%">
        <DrawerContent p="4" height="100%">
          <DrawerCloseButton mt="6" />

          <DrawerHeader boxShadow="none">Navegação</DrawerHeader>

          <DrawerBody height="100%">
            <SidebarNav />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Sidebar;
