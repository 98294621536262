import React, {
  InputHTMLAttributes,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { cep, currency, title } from './masks';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  isExpense?: string;
  icon?: React.ComponentType<IconBaseProps>;
  maskType: 'cep' | 'currency' | 'title' | 'none';
}

const Input: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  isExpense = 'false',
  maskType,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isExpenseColor, setIsExpenseColor] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    if (isExpense === 'true') {
      setIsExpenseColor(true);
    } else {
      setIsFocused(true);
    }
  }, [isExpense]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsExpenseColor(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleKeyUpMask = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (maskType === 'none') {
        return;
      }
      if (maskType === 'cep') {
        cep(e);
      }
      if (maskType === 'currency') {
        currency(e);
      }
      if (maskType === 'title') {
        title(e);
      }
    },
    [maskType],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      isFocusedYellow={isExpenseColor}
    >
      {Icon && <Icon size={20} />}
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        onKeyUp={handleKeyUpMask}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#EB5757" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
