import React, { useRef, useCallback } from 'react';
// import { useState } from 'react';
import { FiLogIn, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
// import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
// import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo-black.svg';

import { Container, Content, AnimationContainer, Background } from './styles';
import Button from '../../components/Button';
import Input from '../../components/Input';
// import api from '../../services/api';

interface ForgotPasswordFormatData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  // const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  // const history = useHistory();

  const handleSubmitCANCEL = useCallback(() => {
    addToast({
      type: 'error',
      title: 'Sem permissão!',
      description:
        'Você nao tem a permissão necessária para recuperar uma senha.',
    });
  }, [addToast]);

  // const handleSubmit = useCallback(
  //   async (data: ForgotPasswordFormatData) => {
  //     try {
  //       setLoading(true);

  //       formRef.current?.setErrors({});

  //       const schema = Yup.object().shape({
  //         email: Yup.string()
  //           .required('E-mail Obrigatório')
  //           .email('Digite um e-mail valido.'),
  //       });

  //       await schema.validate(data, {
  //         abortEarly: false,
  //       });

  //       await api.post('/password/forgot', {
  //         email: data.email,
  //       });

  //       addToast({
  //         type: 'success',
  //         title: 'E-mail',
  //         description:
  //           'Enviamos um e-mail para confirmar a recuperação se senha, cheque sua caixa de entrada.',
  //       });

  //       // history.push('/dashboard');
  //     } catch (err) {
  //       if (err instanceof Yup.ValidationError) {
  //         const errors = getValidationErrors(err);

  //         formRef.current?.setErrors(errors);

  //         return;
  //       }

  //       addToast({
  //         type: 'error',
  //         title: 'Erro na recuperação de senha',
  //         description:
  //           'Ocorreu um erro ao tentar realizar a recupeção de senha, tente novamente.',
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [addToast],
  // );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Tottes" width="200px" />

          <Form ref={formRef} onSubmit={handleSubmitCANCEL}>
            <h1> Recuperar senha</h1>

            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              maskType="none"
            />

            {/* <Button loading={loading} type="submit"> */}
            <Button type="submit">Recuperar</Button>
          </Form>

          <Link to="/signin">
            <FiLogIn />
            Voltar ao login
          </Link>
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
};

export default ForgotPassword;
