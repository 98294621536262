import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import React, { createContext, ReactNode, useContext } from 'react';
import { useState } from 'react';

interface IProdutosNotaFiscal {
  created_at: Date;
  produtos_nota_fiscal_id: number;
  produto_ja_foi_retirado: string;
  produto_quantidade: number;
  produto_valor_unitario: number;
  produto_valor_total: number;
  produto_observacoes: string;
  nome_conta_id: number;
  nome_conta: {
    user_id: string;
    created_at: string;
    updated_at: string;
    nome_conta_id: number;
    nome_conta: string;
  };
  nome_sub_conta_id: number;
  nome_sub_conta: {
    user_id: string;
    created_at: string;
    updated_at: string;
    nome_sub_conta_id: number;
    nome_sub_conta: string;
  };
  produto_marca_id?: number;
  produto_marca?: {
    user_id: string;
    created_at: string;
    updated_at: string;
    produto_marca_id: number;
    produto_marca: string;
  };
  produto_nome_id: number;
  produto_nome: {
    user_id: string;
    created_at: string;
    updated_at: string;
    produto_nome_id: number;
    produto_nome: string;
  };
  produto_unidade_de_medida_id: number;
  produto_unidade_de_medida: {
    user_id: string;
    created_at: string;
    updated_at: string;
    produto_unidade_de_medida_id: number;
    produto_unidade_de_medida: string;
  };
}

interface IPagamentos {
  pagamento_id: number;
  user_id: string;
  notas_fiscais_id: number;
  valor: number;
  data_pagamento: Date;
  data_vencimento: Date;
  forma_pagamento: string;
  desconto: number;
  juros: number;
  multa: number;
  descricao_pagamento: string;
}
interface ModalProviderProps {
  children: ReactNode;
}

type ModalContextData = {
  disclosure: UseDisclosureReturn;
  label: string;
  setLabel: (value: React.SetStateAction<string>) => void;
  produto: IProdutosNotaFiscal;
  setProduto?: (produto: IProdutosNotaFiscal) => void | undefined;
  pagamento: IPagamentos;
  setPagamento?: (pagamento: IPagamentos) => void | undefined;
  id?: number | undefined;
  setId: (value: React.SetStateAction<number | undefined>) => void;
  triggerOnClose: boolean;
  setTriggerOnClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalContext = createContext({} as ModalContextData);

export function ModalProvider({ children }: ModalProviderProps) {
  const [label, setLabel] = useState<string>('');
  const [produto, setProduto] = useState<IProdutosNotaFiscal>(
    {} as IProdutosNotaFiscal,
  );
  const [pagamento, setPagamento] = useState<IPagamentos>({} as IPagamentos);
  const [id, setId] = useState<number>();
  const [triggerOnClose, setTriggerOnClose] = useState<boolean>(false);

  const disclosure = useDisclosure();

  return (
    <ModalContext.Provider
      value={{
        disclosure,
        label,
        setLabel,
        produto,
        setProduto,
        pagamento,
        setPagamento,
        id,
        setId,
        setTriggerOnClose,
        triggerOnClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
