import React, { ElementType } from 'react';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

interface NavLinkProps extends ChakraLinkProps {
  icon?: ElementType;
  children: string;
}

const Navlink: React.FC<NavLinkProps> = ({ icon, children, href, ...rest }) => {
  const { pathname } = useLocation();

  return (
    <ChakraLink
      display="flex"
      align="center"
      href={href}
      {...rest}
      _hover={{
        color: 'brand.green.500',
        textDecoration: 'none',
      }}
      color={pathname === href ? 'brand.green.500' : ''}
    >
      {icon && <Icon as={icon} fontSize="20" />}
      <Text ml="4" fontWeight="medium" textAlign="start">
        {children}
      </Text>
    </ChakraLink>
  );
};

export default Navlink;
