import React, { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

interface ContainerProps {
  children: ReactNode;
}

const ContainerPublic: React.FC<ContainerProps> = ({ children }) => {
  return (
    <Flex direction="column" h="100%" className="container">
      <Header />

      <Flex w="100%" h="100%" my="0" mx="0" px="0">
        <Sidebar />

        {children}
      </Flex>

      <Footer />
    </Flex>
  );
};

export default ContainerPublic;
