import React from 'react';

import { Stack } from '@chakra-ui/react';
// import { RiShieldUserFill, RiDashboardFill } from 'react-icons/ri';
// import {
//   BiMailSend,
//   BiPlusCircle,
//   BiImport,
//   BiSearchAlt2,
//   BiSpreadsheet,
//   BiStats,
// } from 'react-icons/bi';
// import NavSection from './NavSection';
import Navlink from './Navlink';

const SidebarNav: React.FC = () => {
  return (
    <Stack
      spacing="12"
      align="flex-start"
      py="6"
      minWidth="225px"
      height="100%"
    >
      <Navlink href="/quemsomos">Quem somos</Navlink>
      <Navlink href="/consultoria">Consultoria</Navlink>
      <Navlink href="/contabilidade">Contabilidade</Navlink>
    </Stack>
  );
};

export default SidebarNav;
