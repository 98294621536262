import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const colors = {
  brand: {
    green: {
      500: '#539642',
    },
    yellow: {
      500: '#FCD462',
    },
  },
};

const theme = extendTheme({
  config,
  colors,
  styles: {
    global: props => ({
      root: {
        height: 'fit-content',
      },
      body: {
        fontFamily: 'Open Sans',
        color: mode('#333333', 'whiteAlpha.900')(props),
        bg: mode('#fafafa', '#121214')(props),
        lineHeight: 'base',
        fontSize: '14px',
      },

      input: {
        fontSize: '14px',
      },
      h1: {
        fontWeight: 500,
        fontSize: ['16px', '16px'],
        textAlign: 'center',
        paddingBottom: '2px',
        // borderBottomWidth: 2,
      },
      h2: {
        fontWeight: 500,
        fontSize: ['14px', '14px'],
        textAlign: 'center',
        paddingBottom: '2px',
        // borderBottomWidth: 2,
      },
      h3: {
        fontWeight: 500,
        fontSize: ['12px', '12px'],
        textAlign: 'center',
        paddingBottom: '2px',
        // borderBottomWidth: 2,
      },
      header: {
        bg: mode('#ffffff', '#202024')(props),
        boxShadow: mode(
          '0px 2px 4px 0px #acacac',
          '0 2px 6px 0 #121214',
        )(props),
      },
      '.container': {
        bg: mode('#fafafa', '#202024')(props),
      },
      '.sidebar': {
        bg: mode('#ffffff', '#202024')(props),
        boxShadow: mode('0 2px 4px 0 #00000026', 'none')(props),
      },
      '.gradientLine': {
        height: '2px',
        width: '100%',
        bgGradient: mode(
          'linear(to-r, #fafafa, #e2e2e2, #fafafa)',
          'linear(to-r, #121214, #202024, #121214)',
        )(props),
      },
      '.gradientLineForBoxColor': {
        height: '2px',
        width: '100%',
        bgGradient: mode(
          'linear(to-r, #ffffff, #e2e2e2, #ffffff)',
          'linear(to-r, #ffffff00, whiteAlpha.50, #ffffff00)',
        )(props),
      },
      '.boxColor': {
        bg: mode('#ffffff', '#202024')(props),
        borderWidth: mode(1, 'none')(props),
        // boxShadow: mode('0 2px 4px 0 #00000026', 'none')(props),
        boxShadow: mode('0px 2px 4px 0px #acacac', 'none')(props),
      },
      '.boxColorLighter': {
        bg: mode('#ffffff', 'whiteAlpha.50')(props),
        borderWidth: mode(1, 'none')(props),

        // boxShadow: mode('0 2px 4px 0 #00000026', 'none')(props),
      },
    }),
  },
  components: {
    Drawer: {
      // setup light/dark mode component defaults
      baseStyle: props => ({
        dialog: {
          bg: mode('#ffffff', '#202024')(props),
        },
      }),
    },
    Modal: {
      // setup light/dark mode component defaults
      baseStyle: props => ({
        dialog: {
          bg: mode('#ffffff', '#202024')(props),
        },
      }),
    },
    Table: {
      // setup light/dark mode component defaults
      baseStyle: props => ({
        bg: mode('#ffffff', '#202024')(props),
        thead: {
          tr: {
            th: {
              fontWeight: 'semibold',
              fontSize: '12px',
              fontFamily: 'Roboto',
              fontColor: mode('#ffffff', '#202024')(props),
              textTransform: 'none',
            },
          },
        },
        tbody: {
          tr: {
            td: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontColor: '#FCD462',
            },
          },
        },
        tfoot: {
          tr: {
            td: {
              // fontWeight: 'semibold',
              fontSize: '12px',
              fontFamily: 'Roboto',
              fontColor: mode('#ffffff', '#202024')(props),
            },
            '&:last-of-type': {
              td: {
                borderBottomWidth: 0,
              },
            },
          },
        },
      }),

      variants: {
        striped: props => ({
          th: {
            color: mode('#333333', 'whiteAlpha.900')(props),
            // borderColor: '#F7BE56',
            // borderWidth: 1,
            // borderRadius: 8,
          },
          // td: {
          //     borderBottom: string,
          //     borderColor: any,
          // },
          tfoot: {
            tr: {
              '&:last-of-type': {
                td: {
                  borderBottomWidth: 0,
                },
              },
            },
          },
        }),
      },
      sizes: {
        sm: {
          th: {
            fontSize: '10px',
          },
          td: {
            fontSize: '12px',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'light',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '14px',
      },
    },
  },
});

export default theme;
