import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: ${props => (props.color ? props.color : '#a9e838')};
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #333333;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:hover {
    background: ${props => (props.color ? shade(0.2, props.color) : '#C7ED84')};
  }
  /* &:hover {
    background: ${props =>
    props.color ? shade(0.2, props.color) : shade(0.2, '#a9e838')};
  } */
  /* &:hover {
    opacity: 0.8;
  } */
`;
