import React from 'react';
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  VStack,
} from '@chakra-ui/react';

import {
  FaLinkedinIn,
  FaInstagram,
  FaFacebookF,
  FaWhatsapp,
} from 'react-icons/fa';
import Logo from './Logo';

const Footer: React.FC = () => {
  const { colorMode } = useColorMode();

  const isDisplayBiggerThanMedium = useBreakpointValue({
    base: false,
    md: true,
  });

  const isSmallDisplay = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <Flex
      as="footer"
      w="100%"
      h="100%"
      paddingTop="60px"
      paddingX="60px"
      backgroundColor={colorMode === 'light' ? '#F1F2F2' : '#121214'}
    >
      <Stack
        direction="column"
        spacing="64px"
        width="100%"
        maxWidth="1140px"
        paddingBottom="16px"
        // align="flex-start"
        justifyContent="center"
        mx="auto"
      >
        <Stack
          direction={isDisplayBiggerThanMedium ? 'row' : 'column'}
          spacing={isDisplayBiggerThanMedium ? '80px' : '40px'}
          width="100%"
          align={isDisplayBiggerThanMedium ? 'flex-start' : 'center'}
          justifyContent="center"
          mx="auto"
        >
          <VStack
            spacing="16px"
            maxWidth="250px"
            align={isDisplayBiggerThanMedium ? 'flex-start' : 'center'}
            paddingBottom="40px"
          >
            <Logo isSmallDisplay={false} />
            <HStack width="100%" align="flex-start">
              <Link href="https://www.instagram.com/sperottocontadores">
                <IconButton
                  aria-label="Instagram"
                  icon={<Icon as={FaInstagram} />}
                  variant="solid"
                  backgroundColor="#A9E838"
                  borderRadius="full"
                  color="#000000"
                  fontSize="18"
                  _hover={
                    colorMode === 'light'
                      ? { background: '#C7ED84' }
                      : { background: '#8FC331' }
                  }
                />
              </Link>
              <Link href="https://www.facebook.com/sperottocontadores">
                <IconButton
                  aria-label="Facebook"
                  icon={<Icon as={FaFacebookF} />}
                  variant="solid"
                  backgroundColor="#A9E838"
                  borderRadius="full"
                  color="#000000"
                  fontSize="18"
                  _hover={
                    colorMode === 'light'
                      ? { background: '#C7ED84' }
                      : { background: '#8FC331' }
                  }
                />
              </Link>
              <Link href="https://www.linkedin.com/company/sperottocontadores">
                <IconButton
                  aria-label="Linkedin"
                  icon={<Icon as={FaLinkedinIn} />}
                  variant="solid"
                  backgroundColor="#A9E838"
                  borderRadius="full"
                  color="#000000"
                  fontSize="18"
                  _hover={
                    colorMode === 'light'
                      ? { background: '#C7ED84' }
                      : { background: '#8FC331' }
                  }
                />
              </Link>
              <Link href="http://api.whatsapp.com/send?1=pt_BR&phone=5545999149052">
                <IconButton
                  aria-label="Whatsapp"
                  icon={<Icon as={FaWhatsapp} />}
                  variant="solid"
                  backgroundColor="#A9E838"
                  borderRadius="full"
                  color="#000000"
                  fontSize="18"
                  _hover={
                    colorMode === 'light'
                      ? { background: '#C7ED84' }
                      : { background: '#8FC331' }
                  }
                />
              </Link>
            </HStack>
          </VStack>

          <VStack
            align={isDisplayBiggerThanMedium ? 'flex-start' : 'center'}
            spacing={isDisplayBiggerThanMedium ? '16px' : '24px'}
          >
            <Stack direction={isSmallDisplay ? 'column' : 'row'} spacing="80px">
              <VStack
                align="flex-start"
                spacing={isDisplayBiggerThanMedium ? '16px' : '24px'}
              >
                <Stack>
                  <Text fontSize="18px" fontWeight="bold">
                    Augusto José Sperotto
                  </Text>
                  <Text>CRC/PR 021230/O-8</Text>
                </Stack>
                <Stack>
                  <Text fontSize="18px" fontWeight="bold">
                    Jefferson Paulo Martins
                  </Text>
                  <Text>CRC/PR 035401/O-9</Text>
                </Stack>

                <Stack>
                  <Text fontSize="18px" fontWeight="bold">
                    Lisiane Simone Knaack
                  </Text>
                  <Text>CRC/PR 048499/O-1</Text>
                </Stack>
              </VStack>
              <VStack
                align={isDisplayBiggerThanMedium ? 'flex-start' : 'center'}
              >
                <Text fontSize="18px" fontWeight="bold">
                  Toledo/PR
                </Text>
                <Text>Rua da Liberdade, 1778</Text>
                <Text>Toledo - Paraná</Text>
                <Text>85.901-180</Text>
                <Text>+55 (45) 3055-4363</Text>
                <Text>+55 (45) 99132-4872</Text>
              </VStack>
            </Stack>
          </VStack>
        </Stack>
        <Text alignSelf="center">
          Sperotto, Martins & Steffen Contadores Associados LTDA © 2023. Todos
          os direitos reservados
        </Text>
      </Stack>
    </Flex>
  );
};

export default Footer;
