import React from 'react';
import { Flex, Text, VStack, Box, useBreakpointValue } from '@chakra-ui/react';

import ContainerPublic from '../../components/ContainerPublic';

const QuemSomos: React.FC = () => {
  const isDisplayBiggerThanMedium = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <ContainerPublic>
      <Box marginTop="80px" marginBottom="32px" marginX="auto">
        <Flex w="100%" h="100%" p={isDisplayBiggerThanMedium ? '8' : '4'}>
          <VStack w="100%" h="100%" maxWidth="800px" spacing="32px">
            <VStack width="100%">
              <Text
                as="h1"
                fontSize="2.125rem"
                fontWeight="extrabold"
                textAlign="start"
                width="100%"
              >
                Sobre nós
              </Text>
            </VStack>
            <VStack width="100%">
              <Text fontSize="16px" textAlign="start" width="100%">
                Há mais de 35 anos no mercado, contamos com uma carteira de mais
                de{' '}
                <strong>
                  600 clientes em diversas cidades no estado do Paraná
                </strong>
                .
              </Text>
              <Text
                fontSize="16px"
                textAlign="start"
                width="100%"
                paddingTop="32px"
              >
                Toda essa bagagem e os anos de experiência destacaram a{' '}
                <strong>Tottes</strong> por sua capacidade de inovar e
                acompanhar as todas as tendências do mercado.
              </Text>
              <Text
                fontSize="16px"
                textAlign="start"
                width="100%"
                paddingTop="32px"
              >
                Com isso, desmistificamos a ideia de que empresas contábeis
                apenas armazenam dados e geram impostos. Nossa empresa entende e
                oferece o que os empresários precisam:{' '}
                <strong>
                  uma assessoria dinâmica que oriente o seu negócio ao sucesso.
                </strong>
              </Text>
              <Text
                fontSize="16px"
                textAlign="start"
                width="100%"
                paddingTop="32px"
              >
                Por isso, esse conjunto de fatores possibilitou à um pequeno
                escritório se transformar em{' '}
                <strong>
                  referência em contabilidade e consultoria no estado do Paraná.
                </strong>
              </Text>
              <Text
                fontSize="16px"
                textAlign="start"
                width="100%"
                paddingTop="32px"
              >
                Nossa principal preocupação é o resultado de nossos clientes.
                Por isso, identificamos e executamos uma estrategia
                personalizada para cada cliente.
              </Text>
            </VStack>
          </VStack>
        </Flex>
      </Box>
    </ContainerPublic>
  );
};

export default QuemSomos;
