import React from 'react';
import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useColorMode,
} from '@chakra-ui/react';

import { RiMenuLine } from 'react-icons/ri';
import Logo from './Logo';
import { useSidebarDrawer } from '../../../contexts/SidebarDrawerContext';

const Header: React.FC = () => {
  // const { onOpen } = useSidebarDrawer();
  const { colorMode } = useColorMode();

  const { onOpen } = useSidebarDrawer();

  const isDisplayBiggerThanMedium = useBreakpointValue({
    base: false,
    md: true,
  });

  const isSmallDisplay = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <Flex
      as="header"
      w="100%"
      h="75px"
      mx="auto"
      px="4"
      py="2"
      align="center"
      zIndex="1"
      position="fixed"
      opacity="95%"
    >
      <Flex width="100%" maxWidth="1140px" align="center" mx="auto">
        <Logo isSmallDisplay={isSmallDisplay} />

        {isDisplayBiggerThanMedium && (
          <HStack marginLeft="16px" spacing="none">
            <Button href="/quemsomos" as="a" variant="ghost" fontWeight={500}>
              Quem somos
            </Button>
            <Button href="/consultoria" as="a" variant="ghost" fontWeight={500}>
              Consultoria
            </Button>
            <Button
              href="/contabilidade"
              as="a"
              variant="ghost"
              fontWeight={500}
            >
              Contabilidade
            </Button>
            {/* <Button href="/signin" as="a" variant="ghost" fontWeight={500}>
              Carreira
            </Button> */}
          </HStack>
        )}

        {!isDisplayBiggerThanMedium && (
          <IconButton
            aria-label="Open navigation drawer"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="unstyled"
            onClick={onOpen}
            marginLeft="16px"
          />
        )}

        <HStack align="center" ml="auto" spacing="35px">
          {/* Darkmode or LightMode Button */}
          {/* <IconButton
            icon={
              colorMode === 'light' ? (
                <MoonIcon
                  color="gray.400"
                  _hover={{
                    color: 'gray.600',
                  }}
                />
              ) : (
                <SunIcon
                  _hover={{
                    color: 'brand.yellow.500',
                  }}
                />
              )
            }
            variant="link"
            aria-label="Color mode switcher"
            onClick={toggleColorMode}
            border="none"
            _focus={{
              boxShadow: 'none',
            }}
            _hover={{
              backgroundColor: 'transparent',
            }}
          >
            Switch Mode
          </IconButton> */}
          {/* SignIn Button */}
          <Button
            as="a"
            size="md"
            href="/signin"
            width="100%"
            variant="solid"
            fontSize="16px"
            opacity="1"
            textColor="#333333"
            fontWeight="medium"
            backgroundColor="#a9e838"
            _hover={
              colorMode === 'light'
                ? { background: '#C7ED84' }
                : { background: '#8FC331' }
            }
          >
            <Text>Entrar</Text>
          </Button>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Header;
