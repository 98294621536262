import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    height: fit-content;
    box-sizing: border-box;
    font-family: 'Open Sans';
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 20px;
    /* border: 3px solid #333333; */
  }

`;
