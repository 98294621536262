import React from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logoImgBlack from '../../../assets/logo-black.svg';
import logoImgBlackSmall from '../../../assets/logo-black-small.svg';
import logoImgWhite from '../../../assets/logo-white.svg';
import logoImgWhiteSmall from '../../../assets/logo-white-small.svg';

interface LogoProps {
  isSmallDisplay: boolean | undefined;
}

const Logo: React.FC<LogoProps> = ({ isSmallDisplay }) => {
  const { colorMode } = useColorMode();

  return (
    <Link to="/">
      {isSmallDisplay ? (
        <Image
          height="4rem"
          src={colorMode === 'light' ? logoImgBlackSmall : logoImgWhiteSmall}
          alt="Tottes"
          loading="lazy"
        />
      ) : (
        <Image
          height="2rem"
          src={colorMode === 'light' ? logoImgBlack : logoImgWhite}
          alt="Tottes"
          loading="lazy"
        />
      )}

      {/* <img src={logoImg} alt="Tottes" /> */}
    </Link>
  );
};

export default Logo;
