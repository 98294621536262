import React from 'react';
import {
  Button,
  Flex,
  Text,
  VStack,
  Box,
  useBreakpointValue,
  HStack,
  List,
  ListItem,
  ListIcon,
  useColorMode,
  Stack,
  Link,
} from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import semiCircleGrayGreen from '../../assets/semi-circle-gray-green.png';

import ContainerPublic from '../../components/ContainerPublic';

const Contabilidade: React.FC = () => {
  const { colorMode } = useColorMode();
  const isDisplayBiggerThanMedium = useBreakpointValue({
    base: false,
    md: true,
  });
  const isDisplayBiggerThanLarge = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <ContainerPublic>
      <Box marginTop="120px" marginBottom="32px" marginX="auto">
        <Flex w="100%" h="100%" p={isDisplayBiggerThanMedium ? '8' : '4'}>
          <VStack w="100%" h="100%" spacing="120px">
            <VStack width="100%">
              <Text
                as="h1"
                fontSize="2.125rem"
                fontWeight="extrabold"
                textAlign="start"
                width="100%"
              >
                Soluções em Contabilidade
              </Text>
              <Text
                fontSize="16px"
                paddingTop="16px"
                textAlign="start"
                width="100%"
              >
                Somos <strong>referência</strong> em contabilidade e consultoria
                no estado do Paraná.
              </Text>
            </VStack>
            <Stack
              direction={isDisplayBiggerThanLarge ? 'row' : 'column'}
              maxWidth="1140px"
              spacing="30px"
              align="initial"
            >
              <VStack
                id="a"
                width="33,33%"
                maxWidth="350px"
                height="fit-content"
                spacing="0px"
                borderRadius="5px"
                backgroundColor={colorMode === 'light' ? '#fafafa' : '#202024'}
                // opacity="95%"
                _hover={
                  colorMode === 'light'
                    ? { boxShadow: '0 5px 10px rgb(0 0 0 / 15%)' }
                    : { boxShadow: '0 5px 10px rgb(0 0 0 / 50%)' }
                }
              >
                <VStack
                  paddingX="20px"
                  paddingTop="29px"
                  paddingBottom="20px"
                  width="100%"
                >
                  <HStack display="inline-block" position="relative">
                    <Text
                      as="h1"
                      fontSize="24px"
                      fontWeight="semibold"
                      fontFamily="Montserrat"
                    >
                      Express
                    </Text>
                    <Box
                      backgroundImage={semiCircleGrayGreen}
                      backgroundSize="contain"
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                      display="block"
                      width="20px"
                      height="20px"
                      position="absolute"
                      right="-12px"
                      bottom="0px"
                    >
                      {' '}
                    </Box>
                  </HStack>
                </VStack>

                <Flex paddingX="20px" paddingBottom="10px">
                  <Text fontSize="18px">
                    Atendimento online eficiente e acompanhamento anual que
                    garantem um ótimo controle dos resultados da sua empresa.
                  </Text>
                </Flex>

                <VStack paddingBottom="20px" paddingX="20px">
                  <List spacing="10px">
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingTop="10px"
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Levantamento de documentos</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Calendário e obrigações</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Emissão de tributos</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>
                          Reunião anual para apresentação de resultado
                        </Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Atendimento online pela nossa plataforma</Text>
                      </HStack>
                    </ListItem>
                  </List>
                </VStack>

                <Link
                  href="http://api.whatsapp.com/send?1=pt_BR&phone=5545999149052"
                  width="100%"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    id="b"
                    width="100%"
                    height="50px"
                    background="#A9E838"
                    color="#121b21"
                    borderTopRadius="none"
                    _hover={{ bg: '#98D132' }}
                  >
                    QUERO UM ORÇAMENTO
                  </Button>
                </Link>
              </VStack>
              <VStack
                width="33,33%"
                maxWidth="350px"
                height="fit-content"
                spacing="0px"
                borderRadius="5px"
                backgroundColor={colorMode === 'light' ? '#fafafa' : '#202024'}
                // opacity="95%"
                boxShadow={
                  colorMode === 'light'
                    ? '0 5px 10px rgb(0 0 0 / 15%)'
                    : '0 5px 10px rgb(0 0 0 / 50%)'
                }
              >
                <Flex
                  minWidth="115px"
                  marginTop="-9px"
                  marginLeft="35%"
                  marginRight="35%"
                  backgroundColor="#98D132"
                  padding="3px 10px"
                  borderRadius="5px"
                  textAlign="center"
                >
                  <Text
                    width="100%"
                    fontSize="10px"
                    color="#121b21"
                    // fontWeight="semibold"
                  >
                    {' '}
                    MAIS VENDIDO{' '}
                  </Text>
                </Flex>
                <VStack padding="20px" width="100%">
                  <HStack display="inline-block" position="relative">
                    <Text
                      as="h1"
                      fontSize="24px"
                      fontWeight="semibold"
                      fontFamily="Montserrat"
                    >
                      Excellence
                    </Text>
                    <Box
                      backgroundImage={semiCircleGrayGreen}
                      backgroundSize="contain"
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                      display="block"
                      width="20px"
                      height="20px"
                      position="absolute"
                      right="-12px"
                      bottom="0px"
                    >
                      {' '}
                    </Box>
                  </HStack>
                </VStack>

                <Flex paddingX="20px" paddingBottom="10px">
                  <Text fontSize="18px">
                    Acompanhamento eficaz e identificação de planos e
                    estratégias para prosperar o seu negócio e aumentar seu
                    rendimento.
                  </Text>
                </Flex>

                <Flex
                  backgroundColor="#A9E838"
                  width="100%"
                  height="50px"
                  marginTop="20px"
                >
                  <Text textColor="#121b21" fontSize="16px" margin="auto">
                    Todos os itens do <strong>Express</strong> mais:
                  </Text>
                </Flex>

                <VStack paddingBottom="20px" paddingX="20px">
                  <List spacing="10px">
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingTop="10px"
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Estudo da viabilidade do regime tributário</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Análise do cenário</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Planos futuros e estratégias de negócio</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>
                          Reunião semestral para apresentação de resultado
                        </Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Atendimento online e presencial</Text>
                      </HStack>
                    </ListItem>
                  </List>
                </VStack>

                <Link
                  href="http://api.whatsapp.com/send?1=pt_BR&phone=5545999149052"
                  width="100%"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    width="100%"
                    height="50px"
                    background="#A9E838"
                    color="#121b21"
                    borderTopRadius="none"
                    _hover={{ bg: '#98D132' }}
                  >
                    QUERO UM ORÇAMENTO
                  </Button>
                </Link>
              </VStack>
              <VStack
                width="33,33%"
                maxWidth="350px"
                height="fit-content"
                spacing="0px"
                borderRadius="5px"
                backgroundColor={colorMode === 'light' ? '#fafafa' : '#202024'}
                // opacity="95%"
                _hover={{
                  boxShadow:
                    colorMode === 'light'
                      ? '0 5px 10px rgb(0 0 0 / 15%)'
                      : '0 5px 10px rgb(0 0 0 / 50%)',
                }}
              >
                <VStack
                  paddingX="20px"
                  paddingTop="29px"
                  paddingBottom="20px"
                  width="100%"
                >
                  <HStack display="inline-block" position="relative">
                    <Text
                      as="h1"
                      fontSize="24px"
                      fontWeight="semibold"
                      fontFamily="Montserrat"
                    >
                      Premium
                    </Text>
                    <Box
                      backgroundImage={semiCircleGrayGreen}
                      backgroundSize="contain"
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                      display="block"
                      width="20px"
                      height="20px"
                      position="absolute"
                      right="-12px"
                      bottom="0px"
                    >
                      {' '}
                    </Box>
                  </HStack>
                </VStack>

                <Flex paddingX="20px" paddingBottom="10px">
                  <Text fontSize="18px">
                    Nossa principal preocupação é o resultado de nossos
                    clientes. Por isso, o plano <strong>Premium</strong> se
                    adequa de acordo com as necessidades da sua empresa.
                  </Text>
                </Flex>

                <Flex
                  backgroundColor="#A9E838"
                  width="100%"
                  height="50px"
                  marginTop="20px"
                >
                  <Text textColor="#121b21" fontSize="16px" margin="auto">
                    Todos os itens do <strong>Excellence</strong> mais:
                  </Text>
                </Flex>

                <VStack paddingBottom="20px" paddingX="20px">
                  <List spacing="10px">
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingTop="10px"
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Planejamento tributário</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Assessoria trabalhista e previdenciária</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Diagnóstico da empresa e análise de cenário</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>
                          Participação gratuita em treinamentos e palestras
                        </Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Certificação digital gratuita</Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>
                          <strong>Business Inteligence</strong>
                        </Text>
                      </HStack>
                    </ListItem>
                    <ListItem
                      borderBottom="1px"
                      borderColor={
                        colorMode === 'light' ? 'gray.200' : '#676767'
                      }
                      paddingBottom="10px"
                      fontSize="16px"
                    >
                      <HStack>
                        <ListIcon
                          alignSelf="initial"
                          as={MdCheck}
                          color="#676767"
                          height="20px"
                          width="20px"
                        />
                        <Text>Reuniões de acordo com a necessidade</Text>
                      </HStack>
                    </ListItem>
                  </List>
                </VStack>

                <Link
                  href="http://api.whatsapp.com/send?1=pt_BR&phone=5545999149052"
                  width="100%"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    width="100%"
                    height="50px"
                    background="#A9E838"
                    color="#121b21"
                    borderTopRadius="none"
                    _hover={{ bg: '#98D132' }}
                  >
                    QUERO UM ORÇAMENTO
                  </Button>
                </Link>
              </VStack>
            </Stack>
          </VStack>
        </Flex>
      </Box>
    </ContainerPublic>
  );
};

export default Contabilidade;
