import React from 'react';
import {
  Button,
  Flex,
  Text,
  Image,
  VStack,
  Box,
  useBreakpointValue,
  useColorMode,
  Stack,
} from '@chakra-ui/react';
import homeBanner4 from '../../assets/home-banner4.jpg';

import ContainerPublic from '../../components/ContainerPublic';

const SignIn: React.FC = () => {
  const { colorMode } = useColorMode();
  const isDisplayBiggerThanMedium = useBreakpointValue({
    base: false,
    md: true,
  });

  const isDisplayBiggerThanXLarge = useBreakpointValue({
    base: false,
    xl: true,
  });

  return (
    <ContainerPublic>
      <Box>
        <Flex maxHeight="800px" minHeight="800px">
          <Image
            width="100%"
            objectFit="cover"
            // height="3456px"
            src={homeBanner4}
            alt="Home Banner"
            loading="lazy"
          />
          <Flex
            w="100%"
            // h="100%"
            top="120px"
            bottom="auto"
            position="absolute"
            margin="auto"
            // p={isDisplayBiggerThanMedium ? '8' : '4'}
          >
            <VStack
              width="fit-content"
              maxWidth={!isDisplayBiggerThanMedium ? '100%' : '600px'}
              align={!isDisplayBiggerThanMedium ? 'center' : 'flex-start'}
              spacing="0"
              position="relative"
              left={isDisplayBiggerThanXLarge ? '-280px' : 'auto'}
              padding="24px"
              borderRadius={!isDisplayBiggerThanMedium ? 'none' : '8px'}
              backgroundColor={
                colorMode === 'light'
                  ? 'rgba(255, 255, 255, 0.90)'
                  : 'rgba(32, 32, 36, 0.90)'
              }
              // justifyContent="center"
              marginX="auto"
            >
              <Text as="h1" fontSize="2.125rem" fontWeight="extrabold">
                Consultoria e Contabilidade
              </Text>
              <Text as="h1" fontWeight="extrabold">
                Com foco no resultado
              </Text>
              <Text fontSize="16px" paddingTop="16px">
                Há mais de 35 anos no mercado, contamos com uma carteira de mais
                de 600 clientes.
              </Text>
              <Text fontSize="16px" paddingTop="16px">
                Nossa principal preocupação é o resultado de nossos clientes.
                Por isso, identificamos e executamos uma estrategia
                personalizada para cada cliente.
              </Text>
              {/* Soluções em Contabilidade Button */}
              <VStack paddingTop="16px">
                <Text
                  as="h1"
                  alignSelf={
                    !isDisplayBiggerThanMedium ? 'center' : 'flex-start'
                  }
                >
                  Conheça nossas soluções:
                </Text>
                <Stack
                  direction={!isDisplayBiggerThanMedium ? 'column' : 'row'}
                >
                  <Button
                    as="a"
                    size="md"
                    href="/consultoria"
                    width="100%"
                    variant={colorMode === 'light' ? 'solid' : 'outline'}
                    fontSize="16px"
                    textColor={colorMode === 'light' ? '#333333' : '#a9e838'}
                    borderColor={colorMode === 'light' ? '#333333' : '#a9e838'}
                    backgroundColor={
                      colorMode === 'light' ? '#a0da35' : 'transparent'
                    }
                    _hover={
                      colorMode === 'light'
                        ? {
                            backgroundColor: '#C7ED84',
                          }
                        : {
                            textColor: '#000000',
                            backgroundColor: '#a0da35',
                          }
                    }
                  >
                    <Text>Soluções em Consultoria</Text>
                  </Button>
                  <Button
                    as="a"
                    size="md"
                    href="/contabilidade"
                    width="100%"
                    variant={colorMode === 'light' ? 'solid' : 'outline'}
                    fontSize="16px"
                    textColor={colorMode === 'light' ? '#333333' : '#a9e838'}
                    borderColor={colorMode === 'light' ? '#333333' : '#a9e838'}
                    backgroundColor={
                      colorMode === 'light' ? '#a0da35' : 'transparent'
                    }
                    _hover={
                      colorMode === 'light'
                        ? {
                            backgroundColor: '#C7ED84',
                          }
                        : {
                            textColor: '#000000',
                            backgroundColor: '#a0da35',
                          }
                    }
                  >
                    <Text>Soluções em Contabilidade</Text>
                  </Button>
                </Stack>
              </VStack>
            </VStack>
          </Flex>
        </Flex>
      </Box>
    </ContainerPublic>
  );
};

export default SignIn;
